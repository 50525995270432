@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@layer base {
  html {
    font-family: "Lato", sans-serif;
  }
}

.btn {
  @apply rounded bg-neutral-100 py-1 px-2 font-bold text-neutral-900 hover:bg-neutral-800 hover:text-neutral-100 dark:bg-neutral-900 dark:text-neutral-100 dark:hover:bg-neutral-700 sm:py-1.5 sm:px-3;
}

.active-btn {
  @apply bg-gradient-to-r from-red-500 to-purple-700 text-neutral-100 shadow-sm;
}

.container {
  @apply mx-auto max-w-4xl px-3 pt-36 lg:px-0;
}

.hr {
  @apply h-px w-full bg-gradient-to-r from-red-500 via-blue-600 to-purple-700;
}

.about-p {
  @apply mt-8 text-lg leading-relaxed tracking-wider text-neutral-800 dark:text-neutral-200;
}

.linkbtn {
  @apply flex justify-start gap-1 rounded  border  border-neutral-700 bg-neutral-800 px-4 py-2 font-bold text-neutral-100 hover:bg-neutral-700;
}

.fade-transition {
  opacity: 1;
  animation: fade 0.5s linear;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
